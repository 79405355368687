import {
  PUBLIC_BALANSERO_BASE_URL,
  PUBLIC_BALANSERO_FIREBASE_API_KEY,
  PUBLIC_BALANSERO_FIREBASE_APP_ID,
  PUBLIC_BALANSERO_FIREBASE_AUTH_DOMAIN,
  PUBLIC_BALANSERO_FIREBASE_DATABASE_URL,
  PUBLIC_BALANSERO_FIREBASE_MESSAGING_SENDER_ID,
  PUBLIC_BALANSERO_FIREBASE_PROJECT_ID,
  PUBLIC_BALANSERO_FIREBASE_STORAGE_BUCKET,
  PUBLIC_BALANSERO_FIREBASE_MESSAGING_KEY,
  PUBLIC_BALANSERO_SENTRY_DSN,
  PUBLIC_BALANSERO_SENTRY_ENVIRONMENT,
  PUBLIC_BALANSERO_SENTRY_URL,
  PUBLIC_BALANSERO_GEOAPIFY_KEY
} from '$env/static/public';

export const env = {
  firebase: {
    apiKey: PUBLIC_BALANSERO_FIREBASE_API_KEY,
    authDomain: PUBLIC_BALANSERO_FIREBASE_AUTH_DOMAIN,
    databaseURL: PUBLIC_BALANSERO_FIREBASE_DATABASE_URL,
    projectId: PUBLIC_BALANSERO_FIREBASE_PROJECT_ID,
    storageBucket: PUBLIC_BALANSERO_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: PUBLIC_BALANSERO_FIREBASE_MESSAGING_SENDER_ID,
    appId: PUBLIC_BALANSERO_FIREBASE_APP_ID
  },
  sentry: {
    dsn: PUBLIC_BALANSERO_SENTRY_DSN,
    url: PUBLIC_BALANSERO_SENTRY_URL,
    env: PUBLIC_BALANSERO_SENTRY_ENVIRONMENT
  },
  geoapify: {
    key: PUBLIC_BALANSERO_GEOAPIFY_KEY
  },
  baseUrl: PUBLIC_BALANSERO_BASE_URL,
  messagingKey: PUBLIC_BALANSERO_FIREBASE_MESSAGING_KEY
};
